import React, { FC, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Button, Form, Modal, Input, Typography, message } from "antd";

export const InviteFulfiller: FC<{ visible: boolean }> = ({
  visible,
}) => {
  const { isAdmin, sendFulfillerInvite } = useAuth();
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onFinish = async (values: any) => {
    try {
      console.log("values:", values);
      if (!values?.email) return;
      await sendFulfillerInvite.mutateAsync(values.email);
      message.success("Invite successfully sent");
      toggleModal();
    } catch (err) {
      console.error("login error:", err);
      message.error("Send invite error");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  if (isAdmin && visible) {
    return (
      <>
        <Button
          onClick={toggleModal}
          shape="round"
          type="primary"
          style={{ position: "absolute", bottom: 40 }}
          className="m-5"
        >
          Invite fulfiller
        </Button>
        <Modal
          onCancel={toggleModal}
          maskClosable
          footer={null}
          visible={showModal}
        >
          <div className="p-3 mt-2">
            <Typography.Title level={3}>Invite fulfiller</Typography.Title>
            <Form
              layout="vertical"
              name="login"
              initialValues={{}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email!",
                    type: "email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  size="large"
                  style={{ borderRadius: 8 }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={sendFulfillerInvite.isLoading}
                  className="mt-1"
                  style={{ borderRadius: 8, fontWeight: 600 }}
                >
                  Send Invite
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
  return null;
};
