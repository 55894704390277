import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { API } from "../api";
import { getAccessToken, getUser } from "../utils";

const refreshToken = (failedRequest: any) => {
  const user = getUser();
  if (!user) return Promise.reject("user not saved");
  return API.refreshToken(user?.refresh_token).then((res) => {
    if(!res.data) return Promise.reject("no user data on refresh token")
    localStorage.setItem("authToken", res.data.token);
    localStorage.setItem("authUser", res.data);
    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + res.data.token;
    return Promise.resolve();
  });
};

createAuthRefreshInterceptor(axios, refreshToken, {
  statusCodes: [401, 403, 400],
});

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

axios.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  return request;
});
