import React, { FC } from "react";
import {
  Button,
  Row,
  Space,
  Table as TableBase,
  TableProps,
  Typography,
} from "antd";
import { usePagination } from "../hooks/usePagination";
import { SortOrder } from "../types";

interface Props extends TableProps<any> {
  total?: number;
  pageSize?: number;
  onSort?: (column: string, order: SortOrder) => Promise<void> | void;
  onNextPage?: () => void | Promise<void>;
  onPreviousPage?: () => void | Promise<void>;
}

export const Table: FC<Props> = ({
  pageSize,
  total,
  onSort = () => {},
  onNextPage = async () => {},
  onPreviousPage = async () => {},
  pagination,
  ...props
}) => {
  const pag = usePagination({ total, size: pageSize });

  const nextPage = async () => {
    await onNextPage();
    pag.setNextPage();
  };

  const prevPage = async () => {
    await onPreviousPage();
    pag.setPreviousPage();
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    console.log("sorter:", sorter);
    onSort(sorter.field, sorter.order === "descend" ? "desc" : "asc");
  };

  const renderPagination = () => {
    if (!props.loading && pagination !== false) {
      return (
        <Row justify="center" align="middle" className="mt-5">
          <Space>
            <Button
              size="small"
              onClick={prevPage}
              disabled={!pag.previousEnabled}
            >
              Prev
            </Button>
            <Typography.Text>
              {pag.currentPage}/{pag.totalPages || ""}
            </Typography.Text>
            <Button size="small" onClick={nextPage} disabled={!pag.nextEnabled}>
              Next
            </Button>
          </Space>
        </Row>
      );
    }
    return null;
  };

  return (
    <>
      <TableBase
        size="small"
        scroll={{ scrollToFirstRowOnChange: true, x: true, }}
        pagination={false}
        onChange={handleChange}
        style={{ ...props.style }}
        {...props}
      />
      {renderPagination()}
    </>
  );
};
