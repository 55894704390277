import React, { FC } from "react";
import { ContentLayout } from "../../components/ContentLayout";
import { PrivateRouteProps } from "../../types";
import { Breadcrumb, Button, Row, Typography } from "antd";
import { GobackButton } from "../../components/GobackButton";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Table } from "../../components/Table";
import { useInventoryHistory } from "../../hooks/useInventoryHistory";
import { PAGINATION_PAGE_SIZE } from "../../configs";
import moment from "moment";

interface AmountChangedProps {
  oldAmount: number;
  newAmount: number;
}

const AmountChanged: FC<AmountChangedProps> = ({ oldAmount, newAmount }) => {
  let sign = "";
  let color = "";
  if (newAmount > oldAmount) {
    sign = "+";
    color = "#62CE90";
  } else if (oldAmount > newAmount) {
    sign = "-";
    color = "#C0492C";
  }
  return (
    <Typography style={{ color }}>
      {sign}
      {Math.abs(oldAmount - newAmount)}
    </Typography>
  );
};

const columns = [
  {
    title: "Change",
    dataIndex: "change",
    render: (val: string, row: any) => (
      <AmountChanged newAmount={row.quantity} oldAmount={row.old_amount} />
    ),
  },
  { title: "Type", dataIndex: "type" },
  {
    title: "Date",
    dataIndex: "change_date",
    render: (val: string) => val && moment(val).format("MM DD YY, h:mm a"),
  },
  { title: "Fulfiller", dataIndex: "name" },
  { title: "Inventory level", dataIndex: "quantity" },
];

export const InventoryHistory: FC<PrivateRouteProps> = () => {
  const { variantId } = useParams<{ variantId: string }>();
  const location = useLocation<{ sku: string }>();
  const history = useHistory();
  const {
    isLoading,
    items,
    count,
    nextPage,
    previousPage,
  } = useInventoryHistory(Number(variantId));

  return (
    <ContentLayout title="" style={{ backgroundColor: "white" }}>
      <Row align="middle">
        <GobackButton title="" />
        <Breadcrumb>
          <Breadcrumb.Item>Inventory</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Button type="text" onClick={history.goBack} size="small">
              SKU{location.state.sku}
            </Button>
          </Breadcrumb.Item>
          <Breadcrumb.Item>History</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Typography.Title className="mt-3" level={3}>
        Inventory History
      </Typography.Title>
      <Table
        className="mt-5"
        total={count}
        pageSize={PAGINATION_PAGE_SIZE}
        columns={columns}
        loading={isLoading}
        dataSource={items}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
      />
    </ContentLayout>
  );
};
