import { snakeCase } from "lodash";
import { Query } from "../api";
import { UserInfo } from "../types";

export function getQuery(keys: any): Query {
  const newKeys: Query = {};
  Object.entries(keys).forEach(([key, value]) => {
    // @ts-expect-error
    newKeys[snakeCase(key)] = value;
  });
  return newKeys;
}

export function getAccessToken() {
  return localStorage.getItem("authToken");
}

export function getUser(): UserInfo | null {
  const user = localStorage.getItem("authUser");
  if (user) {
    return JSON.parse(user);
  }
  return null;
}
