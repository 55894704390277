import { AxiosResponse } from "axios";
import { DebouncedFunc, throttle } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { QueryObserverResult, useQuery, useQueryClient } from "react-query";
import { API } from "../api";
import { PAGINATION_PAGE_SIZE, SEARCH_THROTTLE_MILISECONDS } from "../configs";

type FulfillerHook = QueryObserverResult<AxiosResponse<any>, unknown> & {
  data: any;
  count: number;
  search: DebouncedFunc<(e: any) => Promise<void>>;
  nextPage: () => void;
  previousPage: () => void;
};

export const useFulfiller = (
  id: number,
  limit = PAGINATION_PAGE_SIZE
): FulfillerHook => {
  // query params states
  const [page, setPage] = useState<string>();
  const [q, setQ] = useState<string>();

  const client = useQueryClient();

  const query = useQuery(
    ["fulfiller", { id, q, page }],
    () => API.getFulfiller(id, { page, q, limit }),
    { keepPreviousData: true }
  );

  const prefetch = useCallback(
    async (page: string) => {
      await client.prefetchQuery(["fulfiller", { id, page }], () =>
        API.getOrders({ limit, page })
      );
    },
    [client, id, limit]
  );

  const data = query.data?.data;

  useEffect(() => {
    const nextPage = data?.next_page;
    if (nextPage) prefetch(nextPage);
  }, [data?.next_page, prefetch]);

  const nextPage = useCallback(() => {
    const nextPage = data?.next_page;
    if (nextPage) setPage(nextPage);
  }, [data?.next_page]);

  const previousPage = useCallback(() => {
    const prevPage = data?.previous_page;
    if (prevPage) setPage(prevPage);
  }, [data?.previous_page]);

  const { current: search } = useRef(
    throttle(async (keyword: string) => {
      if (keyword) {
        setQ(keyword);
      } else {
        setQ(undefined);
      }
    }, SEARCH_THROTTLE_MILISECONDS)
  );

  return {
    ...query,
    search,
    nextPage,
    previousPage,
    count: data?.count || 0,
    data: query.data?.data || {},
  };
};
