import { Alert, Button, Col, Divider, Row, Space, Typography } from "antd";
import React, { FC } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ContentLayout } from "../../components/ContentLayout";
import { GobackButton } from "../../components/GobackButton";
import shopitem from "../../assets/shop-item.png";
import { useInventory } from "../../hooks/useInventory";
import { PrivateRouteProps } from "../../types";
import { Table } from "../../components/Table";
import { UpdateStockLevel } from "../../components/UpdateStockLevel";
import querystring from "query-string";
import { InventoryQuery } from "../../api";
import moment from "moment";

export const InventoryDetail: FC<PrivateRouteProps> = ({ isAdmin }) => {
  const history = useHistory();
  const location = useLocation();
  const q: InventoryQuery = querystring.parse(location.search);
  const inventory = useInventory(q);
  const data = inventory.inventory;

  const getDateTime = (ts: string) => {
    if (!ts) return "";
    return moment(ts).format("MMMM D YYYY, h:mm a");
  };

  const openHistory = () => {
    history.push(
      `/dashboard/inventory/history/${
        q.product_variant_id || data?.inventory[0].product_variant_id
      }`,
      {
        sku: data?.sku,
      }
    );
  };

  const columns = [
    {
      title: "Fulfiller",
      dataIndex: "name",
      render: (val: string, row: any) => (
        <Link className="cursor-pointer" to={`/dashboard/fulfillers/${row.fulfiller_id}`}>{val}</Link>
      ),
    },
    {
      title: "Stock",
      render: (val: any, row: any) => (
        <UpdateStockLevel
          quantity={row.quantity}
          fulfillerId={row.fulfiller_id}
          variantId={row.product_variant_id}
          onSuccess={() => inventory.refetch()}
          split={<div className="w-5" />}
        />
      ),
    },
  ];

  if (!q.product_variant_id && !q.sku) {
    return (
      <div className="p-10">
        <Alert showIcon message="Inventory not found" type="error" />
      </div>
    );
  }

  return (
    <ContentLayout style={{ backgroundColor: "white" }}>
      <GobackButton title="Inventory" />
      <Row justify="space-between" className="mt-1 mb-1">
        <Col>
          <Space
            align="center"
            split={<Divider type="vertical" style={{ height: 25 }} />}
          >
            <Typography.Title style={{ fontSize: 24 }} level={4}>
              SKU{data?.sku}
            </Typography.Title>
            <Typography.Paragraph>
              Last updated at {getDateTime(data?.updated_at || "")}
            </Typography.Paragraph>
          </Space>
        </Col>
        <Col xs={24} md={4}>
          <Button
            type="primary"
            style={{ fontWeight: 600, borderRadius: 14, float: "right" }}
            onClick={openHistory}
          >
            View History
          </Button>
        </Col>
      </Row>
      <Row justify="space-between" className="mb-3">
        <Col xs={24} md={18}>
          <Space align="start">
            <img src={shopitem} alt="shop item" />
            <div className="ml-3">
              <Typography.Text style={{ fontSize: 12 }} className="block">
                Product name:
              </Typography.Text>
              <Typography.Text
                style={{ fontSize: 24, lineHeight: 1.3, fontWeight: 500 }}
                className="block"
              >
                {data?.product_name}
              </Typography.Text>
            </div>
            <div className="ml-3">
              <Typography.Text style={{ fontSize: 12 }} className="block">
                Variant:
              </Typography.Text>
              <Typography.Text
                style={{ fontSize: 24, lineHeight: 1.3, fontWeight: 500 }}
                className="block"
              >
                {data?.product_variant_name}
              </Typography.Text>
            </div>
          </Space>
        </Col>
        <Col sm={{ span: 24 }} md={6}>
          <Row justify="end">
            <div>
              <Typography.Text style={{ fontSize: 12 }} className="block">
                Total inventory:
              </Typography.Text>
              <Typography.Text
                style={{ fontSize: 24, lineHeight: 1.3, fontWeight: 500 }}
                className="block"
              >
                {data?.total_inventory}
              </Typography.Text>
            </div>
            <div className="ml-4">
              <Typography.Text style={{ fontSize: 12 }} className="block">
                Fulfillers:
              </Typography.Text>
              <Typography.Text
                style={{ fontSize: 24, lineHeight: 1.3, fontWeight: 500 }}
                className="block"
              >
                {data?.total_fulfillers}
              </Typography.Text>
            </div>
          </Row>
        </Col>
      </Row>
      <Table
        size="small"
        columns={columns}
        dataSource={data?.inventory}
        loading={inventory.isLoading}
        pagination={false}
      />
    </ContentLayout>
  );
};
