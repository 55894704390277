import { useQuery, UseQueryOptions } from "react-query";
import { API } from "../api";

export const useOrderDetail = (orderId: number, opts: UseQueryOptions = {}) => {
  return useQuery(
    ["orderDetail", orderId],
    () => API.getOrderDetail(orderId),
    opts
  );
};
