import React, { FC } from "react";
import { Row, Col, Typography, Button } from "antd";
import { useAuth } from "../hooks/useAuth";

export type AppHeaderProps = { title?: string; subtitle?: string };

export const AppHeader: FC<AppHeaderProps> = ({ title, subtitle }) => {
  const { signOut } = useAuth();

  if (!title && !subtitle) return null;
  return (
    <header className="pb-5">
      <Row justify="space-between">
        <Col>
          <Typography.Title>{title}</Typography.Title>
          <Typography.Paragraph style={{ marginTop: -12 }} type="secondary">
            {subtitle}
          </Typography.Paragraph>
        </Col>
        <Col>
         <Button onClick={signOut} type="text">Logout</Button>
        </Col>
      </Row>
    </header>
  );
};
