import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { LoginPayload } from "../api";
import { ErrorMessage } from "../components/ErrorMessage";
import { useAuth } from "../hooks/useAuth";
import { AuthCard } from "../components/AuthCard";

export const Login = () => {
  const { signIn } = useAuth();

  const onFinish = async (values: LoginPayload) => {
    try {
      console.log("values:", values);
      await signIn.mutateAsync(values);
    } catch (err) {
      console.error("login error:", err)
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="p-10 background">
      <Row justify="center" align="middle" className="mt-10">
        <Col xs={24} md={12} lg={8}>
          <AuthCard>
            <ErrorMessage
              isError={signIn.isError}
              message="An error occurred"
              className="mb-5"
            />
            <Form
              layout="vertical"
              name="login"
              initialValues={{}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email!",
                    type: "email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  size="large"
                  style={{ borderRadius: 8 }}
                />
              </Form.Item>

              <Form.Item
                label=""
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                    type: "string",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  style={{ height: 50, borderRadius: 8 }}
                />
              </Form.Item>
              <Form.Item style={{ marginTop: -8 }}>
                <Link
                  className="text-gray-900 pt-1 float-right"
                  to="/reset-password"
                >
                  Forgot password?
                </Link>
              </Form.Item>
              <Form.Item style={{ marginTop: -10 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={signIn.isLoading}
                  className="mt-2"
                  style={{ height: 50, borderRadius: 8, fontWeight: 600 }}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </AuthCard>
        </Col>
      </Row>
    </div>
  );
};
