import React, { FC } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CreateUserPassword } from "../pages/CreateUserPassword";
import { DashboardRoutes } from "./DashboardRoutes";
import { Home } from "../pages/Landing";
import { Login } from "../pages/Login";
import { SendResetPasswordEmail } from "../pages/SendRestPasswordEmail";
import { ResetPasswordCallback } from "../pages/ResetPasswordCallback";
import { ScrollToTop } from "../components/ScrollToTop";

const routes = [
  { path: "/", component: Home },
  { path: "/dashboard", component: DashboardRoutes, exact: false },
  { path: "/login", component: Login },
  { path: "/reset-password", component: SendResetPasswordEmail },
  { path: "/reset-password-callback/:token", component: ResetPasswordCallback },
  { path: "/create-user-password/:token", component: CreateUserPassword },
];

export const AppRoute: FC = () => {
  return (
    <Router>
      <ScrollToTop /> {/*Always scroll back to the top on every new route*/}
      <Switch>
        {routes.map((route, i: number) => (
          <Route key={i} exact={true} {...route} />
        ))}
      </Switch>
    </Router>
  );
};
