import { useCallback, useEffect, useRef } from "react";
import { throttle } from "lodash";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { API, OrderQuery } from "../api";
import { Order, OrderStatus, SortOrder } from "../types";
import { PAGINATION_PAGE_SIZE, SEARCH_THROTTLE_MILISECONDS } from "../configs";
import { getQuery } from "../utils";

export const useOrders = ({
  fulfiller_id,
  limit = PAGINATION_PAGE_SIZE,
}: OrderQuery) => {
  // query params states
  const [page, setPage] = useState<string>();
  const [q, setQ] = useState<string>();
  const [status, setStatus] = useState<OrderStatus>();
  const [fulfillerId, setFulfillerId] = useState<number | undefined>(
    fulfiller_id
  );
  const [sortColumn, setSortColumn] = useState<string | undefined>();
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>();

  const client = useQueryClient();
  const queryKeys = getQuery({
    q,
    page,
    status,
    fulfillerId,
    sortColumn,
    sortOrder,
  });

  const query = useQuery(
    ["orders", queryKeys],
    () => API.getOrders({ limit, ...queryKeys }),
    { keepPreviousData: true }
  );

  const prefetch = useCallback(
    async (page: string) => {
      // we only need "page" key, order query keys should be undefined anyway
      await client.prefetchQuery(["orders", { page }], () =>
        API.getOrders({ limit, page })
      );
    },
    [client, limit]
  );

  const data = query.data?.data;

  useEffect(() => {
    const nextPage = data?.next_page;
    if (nextPage) prefetch(nextPage);
  }, [data?.next_page, prefetch]);

  const nextPage = useCallback(() => {
    const nextPage = data?.next_page;
    if (nextPage) setPage(nextPage);
  }, [data?.next_page]);

  const previousPage = useCallback(() => {
    const prevPage = data?.previous_page;
    if (prevPage) setPage(prevPage);
  }, [data?.previous_page]);

  const { current: search } = useRef(
    throttle(async (keyword: string) => {
      if (keyword) {
        setQ(keyword);
      } else {
        setQ(undefined);
      }
    }, SEARCH_THROTTLE_MILISECONDS)
  );

  const filterFulfiller = async (id: number | undefined) => {
    setFulfillerId(id);
  };

  const filterStatus = (status: OrderStatus | undefined) => {
    setStatus(status);
  };

  const sort = async (column: string, order: SortOrder | undefined) => {
    if(order) {
      setSortColumn(column);
      setSortOrder(order);
    } else {
      setSortColumn(undefined);
      setSortOrder(undefined);
    }
  }

  return {
    ...query,
    sort,
    search,
    nextPage,
    previousPage,
    filterFulfiller,
    filterStatus,
    sortOrder,
    count: data?.count || 0,
    orders: data?.items || ([] as Order[]),
  };
};
