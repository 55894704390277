import {
  Space,
  Typography,
  Row,
  Col,
  Button,
  Divider,
  message,
  Input,
  Card,
  Timeline,
  Tooltip,
} from "antd";
import React, { FC, useContext, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { ContentLayout } from "../../components/ContentLayout";
import { GobackButton } from "../../components/GobackButton";
import { AiFillPlusSquare } from "react-icons/ai";
import shopImage from "../../assets/shop-item.png";
import { Context } from "../../store";
import { PrivateRouteProps } from "../../types";
import { GenerateLabelDrawer } from "../../components/GenerateLabelDrawer";
import { Link } from "react-router-dom";
import { OrderStatusBadge } from "../../components/OrderStatusBadge";
import ReactLoading from "react-loading";
import { capitalize } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { useUpdateOrderStatus } from "../../hooks/useUpdateOrderStatus";
import { useOrderDetail } from "../../hooks/useOrderDetail";
import { useAddOrderNotes } from "../../hooks/useAddOrderNotes";

export const OrderDetail: FC<PrivateRouteProps> = ({ isAdmin }) => {
  const location = useLocation<{ createLabel: boolean }>();
  const [createLabel, setCreateLabel] = useState<boolean>(
    location.state?.createLabel
  );

  const { orderId } = useParams<{ orderId: string }>();

  const id = Number(orderId);
  const query = useOrderDetail(id)
  const details = (query.data as any)?.data
  const orderItems: any[] = details?.product_variants || [];

  const toggleCreateLabel = () => {
    setCreateLabel(!createLabel);
  };

  if (query.isError) {
    console.log("order details query error:", query.error);
    message.error("An error occured");
  }

  if (query.isLoading && !query.isError) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="mt-20"
      >
        <ReactLoading type="bars" color="#393550" />
      </div>
    );
  }

  if (query.isError || !details) return null;

  const renderContent = () => {
    if (isAdmin) {
      return (
        <Card
          size="small"
          style={{ borderRadius: 16 }}
          extra={<Typography>1 shipment</Typography>}
          title={
            <Link
              style={{ color: "blueviolet" }}
              to={`/dashboard/fulfillers/${details.fulfiller_id}`}
            >
              Fulfiller:{capitalize(details.fulfiller_name)}
            </Link>
          }
        >
          <OrderItemGroup
            isAdmin={isAdmin}
            orderId={Number(orderId)}
            createLabel={createLabel}
            toggleCreateLabel={toggleCreateLabel}
            details={details}
            groups={[{}]}
            items={orderItems}
          />
        </Card>
      );
    }
    return (
      <OrderItemGroup
        isAdmin={isAdmin}
        orderId={Number(orderId)}
        createLabel={createLabel}
        toggleCreateLabel={toggleCreateLabel}
        details={details}
        groups={[{}]}
        items={orderItems}
      />
    );
  };

  return (
    <ContentLayout style={{ backgroundColor: "white" }}>
      <GobackButton title="Back to orders" />
      <Space
        className="mb-5"
        align="center"
        split={<Divider type="vertical" style={{ height: 25 }} />}
      >
        <Typography.Title style={{ fontSize: 24 }} level={4}>
          Order no: {details.order_number}
        </Typography.Title>
        <Typography.Paragraph>
          Received at April 9, 2021 | 4:09 pm
        </Typography.Paragraph>
      </Space>
      <Row className="pb-10" justify="space-between">
        <Col sm={24} md={15} className="mb-5">
          {renderContent()}
        </Col>
        <Col sm={24} md={8}>
          <OrderViewDetails
            orderItems={orderItems}
            details={{ ...details, orderId }}
          />
          <Card style={{ paddingTop: 10, marginTop: 5 }} bordered={false}>
            <Timeline mode="left">
              {details?.timeline.map((item: any, i: number) => (
                <Timeline.Item
                  color="#08bdbd"
                  label={moment(item.created_at).startOf("hour").fromNow()}
                >
                  {capitalize(item.description)}
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
        </Col>
      </Row>
    </ContentLayout>
  );
};

type OrderGroupProps = {
  orderId: number;
  details: any;
  items: any[];
  groups: any[];
  createLabel: boolean;
  isAdmin: boolean;
  toggleCreateLabel: () => void;
};

const OrderItemGroup: FC<OrderGroupProps> = ({
  isAdmin,
  items,
  createLabel,
  toggleCreateLabel,
  orderId,
  details,
}) => {
  const updateStatus = useUpdateOrderStatus();
  const renderLabelButton = () => {
    if (details.shipping_label) {
      return (
        <a
          style={{ textDecorationLine: "underline" }}
          rel="noreferrer"
          target="_blank"
          href={details.shipping_label}
        >
          View label
        </a>
      );
    }
    if (!isAdmin) {
      return (
        <div>
          <Button
            onClick={toggleCreateLabel}
            size="small"
            type="primary"
            shape="round"
          >
            Generate label
          </Button>
          <GenerateLabelDrawer
            orderId={orderId}
            visible={createLabel}
            toggle={toggleCreateLabel}
          />
        </div>
      );
    }
    return null;
  };

  const renderUpdateStatusButton = () => {
    const markAsShipped = async () => {
      try {
        await updateStatus.mutateAsync({ orderId, status: "shipped" });
        message.success("Successfully marked order as shipped");
      } catch (err) {
        console.error("update order status error:", err);
        message.error("Failed to mark order as shipped");
      }
    };
    if (details.shipping_label && details.status === "label_created") {
      return (
        <Button
          onClick={markAsShipped}
          size="small"
          shape="round"
          type="primary"
        >
          Mark as shipped
        </Button>
      );
    }
    return null;
  };

  const renderTrackingCode = () => {
    if (details?.tracking_code) {
      return (
        <Tooltip title="copy to clipboard" className="mt-4" placement="bottom">
          <CopyToClipboard text={details.tracking_code}>
            <Button shape="round" type="ghost" size="small">
              Tracking code: {details.tracking_code}
            </Button>
          </CopyToClipboard>
        </Tooltip>
      );
    }
    return null;
  };

  const renderSkU = (sku: string) => {
    if (isAdmin) {
      return (
        <Link
          to={`/dashboard/inventory/details?sku=${sku}`}
          className="mt-1"
          style={{ fontWeight: 600 }}
        >
          SKU{sku}
        </Link>
      );
    }
    return (
      <Typography className="mt-1" style={{ fontWeight: 600 }}>
        SKU{sku}
      </Typography>
    );
  };

  return (
    <div className="item-group-container pb-10 mt-3">
      <div className="px-6 pt-4">
        <Row justify="space-between" align="middle">
          <Col>
            <OrderStatusBadge
              status={details.status || "pending"}
              type="large"
            />
          </Col>
          <Col>
            <Space>
              {renderLabelButton()}
              {renderUpdateStatusButton()}
            </Space>
          </Col>
        </Row>
        {items.map((item: any, i: number) => (
          <div className="item-group py-2 px-4 mt-4 bg-white">
            <Row align="top" justify="space-between" className="mt-4">
              <Space align="start">
                <img src={shopImage} alt="shop item" style={{ height: 35 }} />
                <div>
                  <Typography.Text>{item.name}</Typography.Text>
                  <div>{renderSkU(item.sku)}</div>
                </div>
              </Space>
              <Space>
                <Typography.Text style={{ fontWeight: 600 }}>
                  ${item.price} × {item.quantity}
                </Typography.Text>
                <Typography.Text style={{ fontWeight: 600 }} className="ml-4">
                  {Number(item.price) * Number(item.quantity)}
                </Typography.Text>
              </Space>
            </Row>
          </div>
        ))}
        {renderTrackingCode()}
      </div>
    </div>
  );
};

const OrderViewDetails: FC<{
  details: any;
  orderItems: any[];
}> = ({ details, orderItems }) => {
  const notes = useMemo(() => {
    const notes = [...details.notes];
    if (details.customer_note) {
      notes.push({ user_name: "Customer", note: "some note" });
    }
    return notes;
  }, [details.customer_note, details.notes]);

  return (
    <>
      <div className="order-item-info-box">
        <Typography.Title
          level={5}
        >{`${details.customer_first_name} ${details.customer_last_name}`}</Typography.Title>
        <Space
          align="center"
          split={<Divider type="vertical" style={{ height: 15 }} />}
        >
          <Typography.Paragraph>
            {orderItems.length} item(s)
          </Typography.Paragraph>
          <Typography.Paragraph>1 Shipment(s)</Typography.Paragraph>
        </Space>
      </div>

      <div className="order-item-info-box">
        <Typography.Title level={5}>Delivery Address</Typography.Title>
        <Typography.Paragraph>{details.address}</Typography.Paragraph>
      </div>
      <div>
        <div
          className="order-item-info-box"
          style={{ borderWidth: 1, borderColor: "#6B6782" }}
        >
          <Typography.Title level={5} className="mb-1">
            Notes
          </Typography.Title>
          <OrderNotes orderId={details.orderId} notes={notes} />
        </div>
      </div>
    </>
  );
};

const OrderNotes: FC<{ orderId: number; notes: any[] }> = ({
  orderId,
  notes,
}) => {
  const [{ authUser }] = useContext(Context);
  const [note, setNote] = useState<string>("");
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useAddOrderNotes()

  const onNote = (e: any) => {
    setNote(e.target.value);
  };

  const onSave = async () => {
    try {
      if (!note) return;
      await mutateAsync({ orderId, note, userId: authUser.user_id });
      setNote("");
        message.success("Note successfully added");
        await queryClient.invalidateQueries("orderDetail");
    } catch(err) {
      console.error("add note error:", err);
      message.error("Add note failed");
    }
  };

  return (
    <div>
      {notes.map((note, i: number) => (
        <div key={i} className="mb-2">
          <Typography.Text style={{ fontWeight: 600 }}>
            {note.user_name}
          </Typography.Text>
          <Typography.Paragraph>{note.note}</Typography.Paragraph>
        </div>
      ))}
      <Input
        value={note}
        className="mt-3"
        onChange={onNote}
        placeholder="Add a note"
        suffix={
          <Button
            disabled={!note}
            onClick={onSave}
            loading={isLoading}
            size="small"
            icon={<AiFillPlusSquare size={19} />}
          />
        }
      />
    </div>
  );
};
