import { Alert } from "antd";
import React, { FC } from "react";

type Props = { isError: boolean; message: string; className?: string };

export const ErrorMessage: FC<Props> = ({ isError, className, message }) => {
  if (!isError) return null;
  return (
    <div className={className}>
      <Alert showIcon type="error" message={message} />
    </div>
  );
};
