import { Card, Col, Row, Table, Typography } from "antd";
import { take } from "lodash";
import React, { FC } from "react";
import { useOrders } from "../../hooks/useOrders";
import { OrderStatusBadgeGroup } from "../OrderStatusBadge";

export const FulfillerDashboard: FC = () => {
  const { orders, isLoading } = useOrders({});
  return (
    <div>
      <Row>
        <Col span={5}>
          <Card
            size="small"
            style={{ borderRadius: 20, backgroundColor: "#F4F2FF" }}
          >
            <Typography.Paragraph type="secondary" className="text-center">
              Orders filled MTD
            </Typography.Paragraph>
            <Typography.Title
              className="text-center"
              level={1}
              style={{ marginTop: -1 }}
            >
              500
            </Typography.Title>
          </Card>
        </Col>
        <Col span={5} className="ml-5">
          <Card
            size="small"
            style={{ borderRadius: 20, backgroundColor: "#F4F2FF" }}
          >
            <Typography.Paragraph className="text-center" type="secondary">
              Earned
            </Typography.Paragraph>
            <Typography.Title
              className="text-center"
              level={1}
              style={{ marginTop: -1 }}
            >
              12,000
            </Typography.Title>
          </Card>
        </Col>
        <Col span={5} className="ml-5">
          <Card
            size="small"
            style={{ borderRadius: 20, backgroundColor: "#FFF2EC" }}
          >
            <Typography.Paragraph className="text-center" type="secondary">
              Speed
            </Typography.Paragraph>
            <Typography.Title
              className="text-center"
              level={1}
              style={{ marginTop: -1 }}
            >
              Fast
            </Typography.Title>
          </Card>
        </Col>
        <Col span={5} className="ml-5">
          <Card
            size="small"
            style={{ borderRadius: 20, backgroundColor: "#ECFBFF" }}
          >
            <Typography.Paragraph className="text-center" type="secondary">
              Capacity
            </Typography.Paragraph>
            <Typography.Title
              className="text-center"
              level={1}
              style={{ marginTop: -1 }}
            >
              <span style={{ color: "red" }}>30</span>/200
            </Typography.Title>
          </Card>
        </Col>
      </Row>
      <Typography.Title className="mt-5" level={5}>
        Orders for Fulfillment
      </Typography.Title>
      <Table
        size="small"
        pagination={false}
        className="mt-5"
        loading={isLoading}
        columns={orderColumns}
        dataSource={take(orders, 5) as Array<any>}
      />
      <Typography.Title className="mt-5" level={5}>
        Incoming Restock
      </Typography.Title>
      <Table columns={restockColumns} />
    </div>
  );
};

const restockColumns = [
  {
    title: "Reups No.",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Shipping Date",
    dataIndex: "shipping_date",
    key: "shipping_date",
  },
  {
    title: "Receiving Date",
    dataIndex: "receiving_date",
    key: "receiving_date",
  },
  {
    title: "Delivery Date",
    dataIndex: "delivery_date",
    key: "delivery_date",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const orderColumns = [
  {
    title: "Order No.",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Items Status",
    dataIndex: "status",
    key: "status",
    render: (status: string[]) => (
      <OrderStatusBadgeGroup status={["assigned", "assigned", "shipped"]} />
    ),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Customer Name",
    dataIndex: "customer_name",
    key: "customer_name",
  },
  {
    title: "Tracking No",
    dataIndex: "tracking_number",
    key: "Tracking_number",
    render: () => <Typography.Text>n/a</Typography.Text>,
  },
];
