import { Input, InputProps, Row, Col, ColProps } from "antd";
import React, { FC } from "react";
import { AiOutlineSearch } from "react-icons/ai";

interface Props extends InputProps {
  col?: ColProps;
}

export const SearchBar: FC<Props & ColProps> = ({ col = {}, ...props }) => {
  return (
    <Row>
      <Col {...col}>
        <Input
          placeholder="Search"
          className="my-3"
          suffix={<AiOutlineSearch size={18} />}
          {...props}
        />
      </Col>
    </Row>
  );
};
