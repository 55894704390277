import { Card, Col, Row, Table, Typography } from "antd";
import { take } from "lodash";
import React, { FC } from "react";
import { useOrders } from "../../hooks/useOrders";
import { OrderStatusBadgeGroup } from "../OrderStatusBadge";

export const AdminDashboard: FC = () => {
  const { orders, isLoading } = useOrders({});
  return (
    <div>
      <Row>
        <Col span={19}>
          <Row>
            <Col span={6}>
              <Card
                size="small"
                style={{ borderRadius: 20, backgroundColor: "#F4F2FF" }}
              >
                <Typography.Paragraph type="secondary" className="text-center">
                  Orders filled MTD
                </Typography.Paragraph>
                <Typography.Title
                  className="text-center"
                  level={1}
                  style={{ marginTop: -1 }}
                >
                  16,000
                </Typography.Title>
              </Card>
            </Col>
            <Col span={6} className="ml-5">
              <Card
                size="small"
                style={{ borderRadius: 20, backgroundColor: "#F4F2FF" }}
              >
                <Typography.Paragraph className="text-center" type="secondary">
                  Fulfillers
                </Typography.Paragraph>
                <Typography.Title
                  className="text-center"
                  level={1}
                  style={{ marginTop: -1 }}
                >
                  2,000
                </Typography.Title>
              </Card>
            </Col>
            <Col span={6} className="ml-5">
              <Card
                size="small"
                style={{ borderRadius: 20, backgroundColor: "#FFF2EC" }}
              >
                <Typography.Paragraph className="text-center" type="secondary">
                  Stocks
                </Typography.Paragraph>
                <Typography.Title
                  className="text-center"
                  level={1}
                  style={{ marginTop: -1 }}
                >
                  10,000
                </Typography.Title>
              </Card>
            </Col>
          </Row>
          <Typography.Title className="mt-5" level={5}>
            Recent Orders
          </Typography.Title>
          <Table
            pagination={false}
            className="mt-5"
            style={{ maxWidth: 750 }}
            loading={isLoading}
            columns={columns}
            dataSource={take(orders, 5) as Array<any>}
          />
        </Col>
        <Col span={5}>
          <Card
            style={{
              height: 573,
              backgroundColor: "#E6EEF0",
              borderRadius: 24,
            }}
          >
            <Typography.Title level={5}>Restock</Typography.Title>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const columns = [
  {
    title: "Order No.",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Items Status",
    dataIndex: "status",
    key: "status",
    render: (status: string[]) => (
      <OrderStatusBadgeGroup status={["assigned", "assigned", "shipped"]} />
    ),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
];
