import { useQuery } from "react-query";
import { API } from "../api";

export const useOrderSummary = (orderIds: number[]) => {
  const query = useQuery(["order-summary", orderIds.join(",")], () =>
    API.getOrderSummary(orderIds)
  );
  return {
    ...query,
    data: query.data?.data,
  };
};
