import { useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { API } from "../api";
import { Context } from "../store";
import { actionTypes } from "../store/actionTypes";
import { Auth } from "../types";

// todo: move this implementation outside of react hook
// todo: need to reconcile local storage for "authUser" with context data ...
// when axios refresh token interceptor is triggered.

const useSignIn = () => {
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  return useMutation(API.login, {
    onSuccess: (data) => {
      console.log("login response:", data.data);
      dispatch({ type: actionTypes.setAuthUser, payload: data.data });
      localStorage.setItem("authUser", JSON.stringify(data.data));
      localStorage.setItem("authToken", data.data.token);
      history.replace("/dashboard");
    },
    onError: (error) => {
      console.error("login error:", error);
    },
  });
};

const useCreatePassword = () => {
  const history = useHistory();
  return useMutation(API.createPassword, {
    onSuccess: (_data) => history.replace("/login"),
  });
};

const useRefreshToken = (refreshToken: string) => {
  const refresh = useMutation(API.refreshToken);
  return async () => {
    return refresh.mutateAsync(refreshToken);
  };
};

export const useAuth = (): Auth => {
  const [state, dispatch] = useContext(Context);
  const sendPasswordResetEmail = useMutation(API.resetPassword);
  const resetPassword = useMutation(API.setNewPassword);
  const createPassword = useCreatePassword();
  const refreshToken = useRefreshToken(state.authUser?.refresh_token);
  const verifyToken = async () => {};
  const sendFulfillerInvite = useMutation(API.sendFulfillerInvite);
  const signIn = useSignIn();
  const signOut = async () => {
    dispatch({ type: actionTypes.setAuthUser, payload: null });
    localStorage.clear();
  };

  return {
    signIn,
    signOut,
    refreshToken,
    verifyToken,
    sendFulfillerInvite,
    sendPasswordResetEmail,
    resetPassword,
    createPassword,
    isAdmin: state.authUser?.permissions?.includes("admin"),
    currentUser: state.authUser,
  };
};
