import {
  Space,
  Typography,
  Tabs,
  Form,
  Input,
  Button,
  message,
  Switch,
  Row,
  Col,
} from "antd";
import React, { FC } from "react";
import { Table } from "../../components/Table";
import { Link, useHistory, useParams } from "react-router-dom";
import { ContentLayout } from "../../components/ContentLayout";
import { GobackButton } from "../../components/GobackButton";
import { SearchBar } from "../../components/SearchBar";
import { useFulfiller } from "../../hooks/useFulfiller";
import { PrivateRouteProps } from "../../types";
import prodImage from "../../assets/shop-item.png";
import { UpdateStockLevel } from "../../components/UpdateStockLevel";
import { PAGINATION_PAGE_SIZE } from "../../configs";
import { OrderStatusBadgeGroup } from "../../components/OrderStatusBadge";
import { capitalize } from "lodash";
import moment from "moment";
import { useOrders } from "../../hooks/useOrders";
import { useUpdateAddress } from "../../hooks/useUpdateAddress";
import { useUserDispatch } from "../../hooks/useUserDispatch";

export const FulfillerDetail: FC<PrivateRouteProps> = () => {
  const { id } = useParams<{ id: string }>();
  const fulfillerId = Number(id);
  const { enableDispatch } = useUserDispatch(fulfillerId);
  const {
    data,
    nextPage,
    previousPage,
    search,
    count,
    isLoading,
    refetch
  } = useFulfiller(fulfillerId);

  const onDispatch = async (checked: boolean) => {
    try {
      await enableDispatch(checked);
      await refetch(); // toggling dispatch may likely change state of fulfiller data
    } catch (err) {
      console.error("enable dispatch error:", err);
    }
  };

  const columns = [
    {
      render: () => (
        <img src={prodImage} alt="product" style={{ height: 35 }} />
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      render: (val: string) => (
        <Link to={`/dashboard/inventory/details?sku=${val}`}>{val}</Link>
      ),
    },
    { title: "Product", dataIndex: "product_name" },
    { title: "Brand", dataIndex: "brand" },
    { title: "Incoming", dataIndex: "incoming" },
    {
      title: "Stock",
      dataIndex: "inventory",
      render: (val: string, row: any) => (
        <UpdateStockLevel
          quantity={Number(val)}
          variantId={row.product_variant_id}
          fulfillerId={fulfillerId}
          split={<div className="w-2" />}
        />
      ),
    },
  ];

  return (
    <ContentLayout title="" style={{ backgroundColor: "white" }}>
      <Space align="center" style={{ marginTop: -10 }} className="mb-5">
        <GobackButton />
        <Typography.Title className="mt-2" style={{ fontSize: 24 }}>
          {capitalize(data?.name)}
        </Typography.Title>
      </Space>

      <Row justify="end">
        <Col>
          <Space>
            <Switch checked={!!data?.dispatch_enabled} onChange={onDispatch} />
            <Typography>Dispatch enabled</Typography>
          </Space>
        </Col>
      </Row>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Inventory" key="1">
          <SearchBar
            className="mb-3"
            col={{ xs: 24, md: 12, lg: 8 }}
            placeholder="Search SKU/Product"
            onChange={(e: any) => search(e.target.value)}
          />
          <Table
            total={count}
            columns={columns}
            loading={isLoading}
            dataSource={data.items}
            pageSize={PAGINATION_PAGE_SIZE}
            onNextPage={nextPage}
            onPreviousPage={previousPage}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Orders" key="2">
          <UserOrders userId={fulfillerId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Details" key="3">
          <Details data={{ ...data, id }} />
        </Tabs.TabPane>
      </Tabs>
    </ContentLayout>
  );
};

const Details: FC<{ data: any }> = ({ data }) => {
  const updateAddress = useUpdateAddress();
  const onFinish = async (values: any) => {
    try {
      console.log(values);
      await updateAddress.mutateAsync({ userId: data.id, payload: values });
      message.success("Address updated");
    } catch (err) {
      console.error("update address error:", err);
      message.error("Update address error");
    }
  };

  return (
    <div>
      <Space split={<div className="w-5" />}>
        <div>
          <Typography style={{ fontSize: 12, fontWeight: "normal" }}>
            Unshipped:
          </Typography>
          <Typography
            style={{
              color: "#B4AFCD",
              fontSize: 24,
              textAlign: "right",
              fontWeight: 600,
            }}
          >
            0
          </Typography>
        </div>
        <div>
          <Typography style={{ fontSize: 12, fontWeight: 600 }}>
            Total Inventory:
          </Typography>
          <Typography
            style={{
              fontSize: 24,
              textAlign: "right",
              fontWeight: 600,
            }}
          >
            {data.inventory}
          </Typography>
        </div>
        <div>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 600,
              textAlign: "right",
            }}
          >
            Total SKUs:
          </Typography>
          <Typography
            style={{
              fontSize: 24,
              textAlign: "right",
              fontWeight: 600,
            }}
          >
            {data.sku}
          </Typography>
        </div>
      </Space>

      <div className="mt-8">
        <Form
          onFinish={onFinish}
          initialValues={data}
          labelCol={{ lg: 2, md: 3 }}
          wrapperCol={{ xs: 24, md: 16, lg: 8 }}
          labelAlign="left"
        >
          <Form.Item
            label="Street 1"
            name="street1"
            rules={[{ required: true }]}
          >
            <Input placeholder="Street 1" />
          </Form.Item>
          <Form.Item label="Street 2" name="street2">
            <Input placeholder="Street 2" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true }]}
          >
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item
            label="Zip code"
            name="zip_code"
            rules={[{ required: true }]}
          >
            <Input placeholder="Zip code" />
          </Form.Item>
          <Form.Item label="State" name="state" rules={[{ required: true }]}>
            <Input placeholder="State" />
          </Form.Item>
          <Form.Item label="City" name="city" rules={[{ required: true }]}>
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
            <Input placeholder="Phone" type="tel" />
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="mt-5"
              style={{ fontWeight: 600, float: "right" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

// todo: reuse orders
const UserOrders: FC<{ userId: number }> = ({ userId }) => {
  const history = useHistory();
  const {
    isLoading,
    orders,
    count,
    nextPage,
    previousPage,
    search,
  } = useOrders({
    fulfiller_id: userId,
  });

  const onRowClick = (row: any, e: any) => {
    console.log("Target:", e.target.name);
    history.push(`/dashboard/orders/${row.id}`);
  };

  const columns = [
    {
      title: "Order no.",
      dataIndex: "order_number",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      sorter: true,
      render: (val: string) => moment(val).format("l"),
    },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: () => <OrderStatusBadgeGroup status={["assigned"]} />,
    },
    {
      title: "Customer name",
      dataIndex: "customer_name",
      render: (val: string) => capitalize(val),
    },
  ];
  return (
    <>
      <SearchBar
        col={{ xs: 24, md: 12, lg: 8 }}
        placeholder="Search order"
        onChange={(e: any) => search(e.target.value)}
      />
      <Table
        columns={columns}
        total={count}
        pageSize={PAGINATION_PAGE_SIZE}
        loading={isLoading}
        dataSource={orders}
        onNextPage={nextPage}
        onPreviousPage={previousPage}
        onRow={(record, index) => ({
          onClick: (e) => onRowClick(record, e),
        })}
      />
    </>
  );
};
