import axios, { AxiosResponse } from "axios";
import querystring from "query-string";
import { Key } from "react";
import { Address, Inventory, Order, OrderStatus, User } from "../types";

export interface Query {
  page?: string;
  limit?: number;
  q?: string;
  sort_column?: string;
  sort_order?: string;
}

export interface OrderQuery extends Query {
  fulfiller_id?: number;
  status?: OrderStatus;
}

export interface InventoryQuery {
  fulfiller_id?: number;
  sku?: number | string;
  product_variant_id?: number | string;
}

export interface InventoryHistoryQuery extends Query {
  product_variant_id?: number;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface CreatePasswordPayload {
  token: string;
  name?: string;
  password: string;
}

export type CreateUserPayload = CreatePasswordPayload & Address;

export interface UpdateStockPayload {
  product_variant_id: number | string;
  fulfiller_id: number;
  location: string;
  inventory: number;
}

export interface UpdateAddressPayload {
  street1: string;
  street2?: string;
  country: string;
  zip_code: string;
  state: string;
  city: string;
  phone?: string;
}

export interface AddOrderNotesPayload {
  userId: number;
  orderId: number;
  note: string;
}

export interface CreatePackagePayload {
  width: number;
  height: number;
  length: number;
  weight: number;
}

export interface PageResponse<T> {
  previous_page: string;
  next_page: string;
  count: number;
  items: T[];
}

class APIBase {
  getOrders(q: OrderQuery = {}): Promise<AxiosResponse<PageResponse<Order>>> {
    return axios.get(`/order?${querystring.stringify(q)}`);
  }

  getOrderDetail(id: number): Promise<AxiosResponse<any>> {
    return axios.get(`/order/${id}`);
  }

  getOrderSummary(orderIds: number[]) {
    return axios.get(`/order-summary?order_ids=${orderIds.join(",")}`);
  }

  getProducts(q: Query = {}): Promise<AxiosResponse<PageResponse<any>>> {
    return axios.get(`/product?${querystring.stringify(q)}`);
  }

  getInventory(query: InventoryQuery = {}): Promise<AxiosResponse<Inventory>> {
    return axios.get(`/inventory?${querystring.stringify(query)}`);
  }

  getInventoryHistory(
    query: InventoryHistoryQuery = {}
  ): Promise<AxiosResponse<PageResponse<any>>> {
    return axios.get(`/inventory-history?${querystring.stringify(query)}`);
  }

  getFulfillers(query: Query = {}): Promise<AxiosResponse<PageResponse<User>>> {
    return axios.get(`/fulfiller?${querystring.stringify(query)}`);
  }

  getFulfiller(
    id: number,
    query: Query = {}
  ): Promise<AxiosResponse<PageResponse<any>>> {
    return axios.get(`/user/${id}?${querystring.stringify(query)}`);
  }

  enableDispatch({ userId, value }: { userId: Key; value: boolean }) {
    return axios.post(`/user/${userId}/dispatch`, {
      dispatch_enabled: value,
    });
  }

  updateStock(data: any) {
    return axios.post("/inventory", data);
  }

  updateAddress({
    userId,
    payload,
  }: {
    userId: string;
    payload: UpdateAddressPayload;
  }) {
    return axios.patch(`/user/${userId}/address`, payload);
  }

  updateOrderStatus({
    orderId,
    status,
  }: {
    orderId: number;
    status: OrderStatus;
  }) {
    return axios.patch(`/order/${orderId}`, { status });
  }

  addOrderNotes(data: AddOrderNotesPayload) {
    const { userId, orderId, note } = data;
    return axios.post(`/order/${orderId}/note`, {
      user_id: userId,
      note,
    });
  }

  login(payload: LoginPayload) {
    return axios.post("/login", payload);
  }

  setNewPassword(payload: CreatePasswordPayload) {
    return axios.post("/login/reset-password-callback", payload);
  }

  createPassword(payload: CreatePasswordPayload) {
    return axios.post("/user/password", payload);
  }

  resetPassword(email: string) {
    return axios.post("/login/reset-password", { email });
  }

  refreshToken(refreshToken: string) {
    return axios.post("/refresh-token", { refresh_token: refreshToken });
  }

  sendFulfillerInvite(email: string) {
    return axios.post("/user", { email, permissions: ["fulfiller"] });
  }

  createPackage({
    orderId,
    payload,
  }: {
    orderId: number;
    payload: CreatePackagePayload;
  }) {
    return axios.patch(`/order/${orderId}/package`, payload);
  }

  purchaseLabel({ orderId, rateId }: { orderId: number; rateId: string }) {
    return axios.patch(`/order/${orderId}/buy`, { rate_id: rateId });
  }
}

export const API = new APIBase();
