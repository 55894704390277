import { Typography } from "antd";
import React, { FC } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";

type Props = {
  onGoBack?: () => void;
  title?: string;
};

export const GobackButton: FC<Props> = ({ onGoBack, title = "" }) => {
  const history = useHistory();
  const goBack = onGoBack || history.goBack;
  return (
    <div onClick={goBack} style={{ cursor: "pointer" }} className="flex items-center">
      <BiArrowBack size={20} />
      <Typography.Text className="ml-2" style={{ fontSize: 16 }}>{title}</Typography.Text>
    </div>
  );
};
