import React, { CSSProperties, FC, ReactNode } from "react";
import { AppHeader, AppHeaderProps } from "./AppHeader";

type Props = AppHeaderProps & {
  children: ReactNode;
  style?: CSSProperties | undefined;
  className?: string | undefined;
};

export const ContentLayout: FC<Props> = ({
  children,
  style,
  className,
  ...rest
}) => {
  return (
    <div
      style={{ ...ContentLayout.defaultProps?.style, ...style }}
      className={className}
    >
      <AppHeader {...rest} />
      {children}
    </div>
  );
};

ContentLayout.defaultProps = {
  className: "px-10 py-5",
  style: { backgroundColor: "#F8F9FB", minHeight: "100vh" },
};
