import React, { FC } from "react";
import { AutoComplete, Input } from "antd";
import { AiOutlineFilter } from "react-icons/ai";
import { useFulfillers } from "../hooks/useFulfilers";
import { capitalize } from "lodash";

interface Props {
  onSelect?: (id: number | undefined) => Promise<void> | void;
}

export const FulfillerAutoComplete: FC<Props> = ({ onSelect = () => {} }) => {
  const { items } = useFulfillers();
  
  const options = items.map((item) => ({
    value: item.name,
    label: capitalize(item.name || item.email),
  }));

  const handleSelect = (val: string) => {
    const selection = items.find((item) => item.name === val);
    if (selection) onSelect(selection.id);
  };

  const handleClear = () => {
    onSelect(undefined)
  }

  return (
    <AutoComplete
      allowClear
      onClear={handleClear}
      onSelect={handleSelect}
      bordered={false}
      style={{ width: "100%" }}
      className="my-3"
      options={options}
      filterOption={(inputValue = "", option) => {
        return (
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        );
      }}
    >
      <Input
        placeholder="Assigned fulfiller"
        suffix={<AiOutlineFilter size={18} />}
      />
    </AutoComplete>
  );
};
