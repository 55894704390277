import { AxiosResponse } from "axios";
import { QueryObserverResult, useQuery } from "react-query";
import { API, InventoryQuery } from "../api";
import { Inventory } from "../types";

type InventoryHook = QueryObserverResult<AxiosResponse<Inventory>, unknown> & {
  inventory?: Inventory;
};

export const useInventory = (q: InventoryQuery): InventoryHook => {
  const query = useQuery(["inventory", ...Object.values(q)], () =>
    API.getInventory(q)
  );
  console.log("inventiory details:", query.data?.data);
  return {
    ...query,
    inventory: query.data?.data,
  };
};
