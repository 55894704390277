import { Typography } from "antd";
import React, { FC } from "react";
import logo from "../assets/logo.png";

export const AuthCard: FC = (props) => {
  return (
    <div style={{ backgroundColor: "white", padding: 50, borderRadius: 16 }}>
      <div className="flex items-center">
        <div>
          <img src={logo} alt="logo" />
        </div>
        <Typography
          className="ml-3"
          style={{ lineHeight: 0.9, fontSize: 32, fontWeight: 700 }}
        >
          Fulfillment by Everyone
        </Typography>
      </div>
      <div className="mt-12">{props.children}</div>
    </div>
  );
};
