import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { ContentLayout } from "../../components/ContentLayout";
import { UserDashboard } from "../../components/UserDashboard";
import { PrivateRouteProps } from "../../types";

export const Home: FC<PrivateRouteProps> = ({ isAdmin }) => {
  return (
    <>
      <Redirect to="/dashboard/orders" />
      <ContentLayout title="Dashboard" subtitle="Today's update">
        {isAdmin ? <UserDashboard.Admin /> : <UserDashboard.Fulfiller />}
      </ContentLayout>
    </>
  );
};
