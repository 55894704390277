import { useMutation } from "react-query";
import { API } from "../api";

export const useUserDispatch = (userId: React.Key) => {
  const { mutateAsync, isLoading, ...rest } = useMutation(API.enableDispatch);

  return {
    ...rest,
    isLoading,
    enableDispatch(value: boolean) {
      if (isLoading) return;
      return mutateAsync({ userId, value });
    },
  };
};
