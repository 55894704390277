import { throttle } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { SEARCH_THROTTLE_MILISECONDS } from "../configs";
import { SortOrder } from "../types";
import { getQuery } from "../utils";
import { API } from "../api";

export const useProducts = (limit = 50) => {
  const client = useQueryClient();
  const [page, setPage] = useState<string>("");
  const [q, setQ] = useState<string>();
  const [sortColumn, setSortColumn] = useState<string>();
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  const queryKeys = getQuery({ q, page, sortColumn, sortOrder });

  const query = useQuery(
    ["products", queryKeys],
    () => API.getProducts({ limit, ...queryKeys }),
    { keepPreviousData: true }
  );

  const prefetch = useCallback(
    async (page: string) => {
      await client.prefetchQuery(["products", { page }], () =>
        API.getProducts({ limit, page })
      );
    },
    [client, limit]
  );

  const data = query.data?.data;

  useEffect(() => {
    const nextPage = data?.next_page;
    if (nextPage) prefetch(nextPage);
  }, [prefetch, data?.next_page]);

  const nextPage = useCallback(() => {
    const page = data?.next_page;
    if (page) setPage(page);
  }, [data?.next_page]);

  const previousPage = useCallback(() => {
    const page = data?.previous_page;
    if (page) setPage(page);
  }, [data?.previous_page]);

  const { current: search } = useRef(
    throttle(async (keyword: string) => {
      if (keyword) {
        setQ(keyword);
      } else {
        setQ(undefined);
      }
    }, SEARCH_THROTTLE_MILISECONDS)
  );

  const sort = async (column: string, order: SortOrder) => {
    setSortColumn(column);
    setSortOrder(order);
  };

  console.log("products:", query.data?.data);

  return {
    ...query,
    products: data?.items || [],
    count: data?.count || 0,
    search,
    nextPage,
    previousPage,
    sort,
  };
};
