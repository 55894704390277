import React, { FC } from "react";
import { AppRoute } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Store from "./store";
import "./configs/axios";
import "./App.less";

const queryClient = new QueryClient();

const App: FC = () => (
  <Store>
    <QueryClientProvider client={queryClient}>
      <AppRoute />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Store>
);

export default App;
