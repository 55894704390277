import { Row, Space, Typography } from "antd";
import React, { CSSProperties, FC } from "react";
import { OrderStatus } from "../types";

type BaseProps = { type?: "default" | "large" };

const outlineStyle: CSSProperties = {
  borderWidth: 1,
  borderColor: "#4A7178",
  borderRadius: 16,
  padding: "4px 10px",
  width: "fit-content",
};

const StatusBadgeBase: FC<{ style?: React.CSSProperties }> = (props) => (
  <div
    style={{
      width: 18,
      height: 18,
      borderRadius: 9,
      borderWidth: 2,
      boxSizing: "border-box",
      ...props.style,
    }}
  ></div>
);

const LabelCreatedBadge: FC<BaseProps> = ({ type }) => {
  if (type === "large") {
    return (
      <Row align="middle" style={outlineStyle}>
        <StatusBadgeBase style={{ backgroundColor: "#4A7178" }} />
        <Typography.Text style={{ fontSize: 12 }} className="ml-1">
          label created
        </Typography.Text>
      </Row>
    );
  }
  return (
    <StatusBadgeBase
      style={{ backgroundColor: "#4A7178", borderColor: "#4A7178" }}
    />
  );
};

const AssignedBadge: FC<BaseProps> = ({ type }) => {
  return (
    <Row align="middle" style={type === "large" ? outlineStyle : undefined}>
      <StatusBadgeBase style={{ borderColor: "#4A7178" }} />
      {type === "large" ? (
        <Typography.Text style={{ fontSize: 12 }} className="ml-1">
          assigned
        </Typography.Text>
      ) : null}
    </Row>
  );
};

const ShippedBadge: FC<BaseProps> = ({ type }) => (
  <Row align="middle" style={type === "large" ? outlineStyle : undefined}>
    <div
      style={{
        width: 18,
        height: 18,
        borderRadius: 9,
        backgroundColor: "#4A7178",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 8,
          height: 8,
          borderRadius: 4,
          borderWidth: 2,
          backgroundColor: "white",
          margin: "0 auto",
        }}
      ></div>
    </div>
    {type === "large" ? (
      <Typography.Text style={{ fontSize: 12 }} className="ml-1">
        shipped
      </Typography.Text>
    ) : null}
  </Row>
);

const DeliveredBadge: FC<BaseProps> = ({ type }) => (
  <Row align="middle" style={type === "large" ? outlineStyle : undefined}>
    <StatusBadgeBase
      style={{ backgroundColor: "#AA83EF", borderColor: "#AA83EF" }}
    />
    {type === "large" ? (
      <Typography.Text style={{ fontSize: 12 }} className="ml-1">
        delivered
      </Typography.Text>
    ) : null}
  </Row>
);

const PendingBadge: FC<BaseProps> = ({ type }) => (
  <Row align="middle" style={type === "large" ? outlineStyle : undefined}>
    <StatusBadgeBase
      style={{ backgroundColor: "white", borderColor: "#E6EEF0" }}
    />
    {type === "large" ? (
      <Typography.Text style={{ fontSize: 12 }} className="ml-1">
        pending
      </Typography.Text>
    ) : null}
  </Row>
);

export const OrderStatusBadge: FC<{ status: OrderStatus } & BaseProps> = ({
  status,
  type,
}) => {
  switch (status) {
    case "assigned":
      return <AssignedBadge type={type} />;
    case "delivered":
      return <DeliveredBadge type={type} />;
    case "label_created":
      return <LabelCreatedBadge type={type} />;
    case "shipped":
      return <ShippedBadge type={type} />;
    case "pending":
      return <PendingBadge type={type} />;
    default:
      return null;
  }
};

export const OrderStatusBadgeGroup: FC<{ status: OrderStatus[] }> = ({
  status,
}) => {
  return (
    <Space direction="horizontal" align="center">
      {status.map((stat, i: number) => (
        <OrderStatusBadge status={stat} key={i} />
      ))}
    </Space>
  );
};
