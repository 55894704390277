import { useMutation } from "react-query";
import { API } from "../api";

export const useLabel = () => {
  const createPackage = useMutation(API.createPackage);
  const purchaseLabel = useMutation(API.purchaseLabel);

  return {
    createPackage,
    purchaseLabel,
  };
};
