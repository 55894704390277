import React, { FC, useMemo, useState } from "react";
import { Layout, Menu } from "antd";
import { Link, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { capitalize } from "lodash";
import logo from "../assets/logo.png";
import { PrivateRoute } from "./PrivateRoute";
import { useDashboardRoutes } from "../hooks/useDashboardRoutes";
import { InviteFulfiller } from "../components/InviteFulfiller";

const { Content, Footer, Sider } = Layout;

type MenuItemProps = { icon: any; title: string; active: boolean };

const MenuItem: FC<MenuItemProps> = ({ icon: Icon, title, active }) => {
  const color = active ? "#393550" : "#B4AFCD";
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
      <Icon style={{ color }} size={22} />
      <span style={{ color }} className="Sidebar-item-title pl-4">
        {capitalize(title)}
      </span>
    </div>
  );
};

export const DashboardRoutes: React.FC = () => {
  const { location } = useHistory();
  const { path, url } = useRouteMatch();
  const activeSubPath = location.pathname.split("/")[2];
  const { getRoutes, routeItems } = useDashboardRoutes();
  const routes = useMemo(() => getRoutes(path), [getRoutes, path]);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout>
      <Sider
        width={230}
        className="sider"
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(col, type) => {
          console.log("collaped:", col, type);
          setCollapsed(col);
        }}
      >
        <Menu
          mode="inline"
          theme="light"
          style={{ borderRightColor: "transparent", padding: 30 }}
        >
          <Link to="/dashboard">
            <img
              src={logo}
              alt="logo"
              style={{ height: 74 }}
              className="mb-10"
            />
          </Link>
          {routeItems.map((item, i: number) => (
            <Link key={i} to={item.getLink(url)}>
              <MenuItem
                key={i}
                title={item.name}
                icon={item.icon}
                active={activeSubPath === item.path}
              />
            </Link>
          ))}
        </Menu>
        <InviteFulfiller visible={!collapsed} />
      </Sider>
      <Layout>
        <Content
          className="bg-white"
          style={{ width: "100%" }}
          //style={{ margin: "24px 16px 0", height: "100vh" }}
        >
          <Switch>
            {routes.map((route, i: number) => (
              <PrivateRoute key={i} exact {...route} />
            ))}
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center" }}>FBE ©2021</Footer>
      </Layout>
    </Layout>
  );
};
