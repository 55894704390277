import { Row, Space, Typography, Button, Modal, Divider, Table } from "antd";
import React, { FC, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Order } from "../types";
import shopitem from "../assets/shop-item.png";
import { useOrderSummary } from "../hooks/useOrderSummary";

export const OrderSummary: FC<{ selected: Order[] }> = ({ selected }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const toggleVisible = () => setVisible(!visible);
  const { data, isLoading } = useOrderSummary(selected.map(i => i.id))

  if (!selected.length) return null;
  return (
    <>
      <Row
        style={{ backgroundColor: "#393550" }}
        justify="space-between"
        className="p-4"
      >
        <Space>
          <Typography.Text
            style={{ color: "white", fontWeight: 600, fontSize: 16 }}
          >
            {selected.length} Selected
          </Typography.Text>
          <Button
            className="ml-4"
            shape="round"
            onClick={toggleVisible}
            style={{
              backgroundColor: "transparent",
              color: "white",
              fontWeight: 600,
            }}
          >
            View SKU summary <AiOutlineMenu className="inline-block ml-1" />
          </Button>
        </Space>
      </Row>
      <Modal
        width={800}
        visible={visible}
        footer={null}
        onCancel={toggleVisible}
      >
        <Row>
          <Typography.Text style={{ fontSize: 18, fontWeight: 600 }}>
            Your selection:
          </Typography.Text>
          <Space split={<Divider type="vertical" />} className="ml-1">
            <Typography.Paragraph style={{ fontSize: 18, fontWeight: 600 }}>
              {selected.length} items from orders
            </Typography.Paragraph>
            <Typography.Paragraph style={{ fontSize: 18, fontWeight: 600 }}>
              {data?.sku_quantity} SKUs
            </Typography.Paragraph>
          </Space>
        </Row>
        <Table
          loading={isLoading}
          size="small"
          columns={columns}
          dataSource={data?.items}
          pagination={{ pageSize: 8, position: ["bottomCenter"] }}
        />
      </Modal>
    </>
  );
};

const columns = [
  {
    title: "SKU",
    dataIndex: "sku",
    width: 180,
    render: (val: string) => (
      <Space>
        <img style={{ height: 30 }} src={shopitem} alt="shop item" />
        {val}
      </Space>
    ),
  },
  {
    title: "Product name",
    dataIndex: "product_name",
  },
  {
    title: "Brand",
    dataIndex: "brand",
  },
  {
    title: "Order quantity",
    dataIndex: "avaliable_stock",
  },
  {
    title: "Availbale stock",
    dataIndex: "avaliable_stock",
  },
  {
    title: "Location in warehouse",
    dataIndex: "location",
  },
];
