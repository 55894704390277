import React, { FC, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Context } from "../store";

export const Home: FC = () => {
  const [state] = useContext(Context);
  console.log("state:", state);
  return (
    <header>
      <Redirect to="/login" />
      <h1>Landing</h1>
    </header>
  );
};
