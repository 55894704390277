import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { PrivateRouteProps } from "../types";

type Props = {
  component: React.FC<PrivateRouteProps>;
  path: string;
  exact: boolean;
};

export const PrivateRoute: React.FC<Props> = ({
  path,
  exact,
  component: Component,
}) => {
  const { currentUser, isAdmin } = useAuth();
  console.log("current user:", currentUser);
  if (currentUser) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props) => (
          <Component {...props} currentUser={currentUser} isAdmin={isAdmin} />
        )}
      />
    );
  }
  return <Redirect to="/login" />;
};
