import React, { FC, useCallback, useMemo, useState } from "react";
import { ContentLayout } from "../../components/ContentLayout";
import { OrderStatus, PrivateRouteProps } from "../../types";
import { Tabs, Row, Col, Button, Tooltip } from "antd";
import { OrderStatusBadge } from "../../components/OrderStatusBadge";
import { useHistory } from "react-router-dom";
import { useOrders } from "../../hooks/useOrders";
import { Order } from "../../types";
import { Table } from "../../components/Table";
import { PAGINATION_PAGE_SIZE } from "../../configs";
import { FulfillerAutoComplete } from "../../components/FulfillerAutoComplete";
import moment from "moment";
import { SearchBar } from "../../components/SearchBar";
import { capitalize } from "lodash";
import { OrderSummary } from "../../components/OrderSummary";

const StatusTabs = [
  "all",
  "assigned",
  "label_created",
  "shipped",
  "delivered",
  "backordered",
];

export const Orders: FC<PrivateRouteProps> = ({ isAdmin }) => {
  const [selectedRows, setSelectedRows] = useState<Order[]>([]);
  const history = useHistory();
  const {
    orders,
    isLoading,
    search,
    count,
    sort,
    nextPage,
    previousPage,
    filterStatus,
    filterFulfiller,
  } = useOrders({});

  const rowSelection = {
    onChange: (keys: React.Key[], rows: Order[]) => {
      console.log(`selectedRowKeys: ${keys}`, "selectedRows: ", selectedRows);
      setSelectedRows(rows);
    },
  };

  const tabCallback = (key: React.Key) => {
    const tab = tabs[Number(key)];
    setSelectedRows([]);
    if (tab === "all") {
      filterStatus(undefined);
    } else {
      filterStatus(tab as OrderStatus);
    }
  };

  const gotoDetails = useCallback(
    (id: string, state = {}) => {
      history.push(`/dashboard/orders/${id}`, state);
    },
    [history]
  );

  const onGenLabel = useCallback(
    (row: any, e: any) => {
      e.stopPropagation();
      gotoDetails(row.id, { createLabel: true });
    },
    [gotoDetails]
  );

  const onRowClick = (row: any, e: any) => {
    gotoDetails(row.id);
  };

  const tabs = useMemo(() => {
    if (isAdmin) return StatusTabs;
    return StatusTabs.filter((tab) => tab !== "assigned");
  }, [isAdmin]);

  const columns = useMemo(
    () => (isAdmin ? adminColumns : getFulfillerColumns({ onGenLabel })),
    [isAdmin, onGenLabel]
  );

  const renderSummary = () => {
    if (!isAdmin && selectedRows?.length) {
      return <OrderSummary selected={selectedRows} />;
    }
    return null;
  };

  const renderFulfillerFilter = () => {
    if (isAdmin) {
      return (
        <Col xs={24} sm={12} md={8}>
          <FulfillerAutoComplete onSelect={filterFulfiller} />
        </Col>
      );
    }
    return null;
  };

  return (
    <ContentLayout title="Orders">
      <div
        style={{ backgroundColor: "white", borderRadius: 16 }}
        className="px-3 py-5"
      >
        <Tabs defaultActiveKey="0" onChange={tabCallback}>
          {tabs.map((tab, i: number) => (
            <Tabs.TabPane tab={capitalize(tab).replaceAll("_", " ")} key={i}>
              <Row
                align="middle"
                justify="space-between"
                gutter={10}
                className="mb-2"
              >
                <Col xs={24} sm={12} md={8}>
                  <SearchBar
                    placeholder="Search order"
                    col={{ xs: 24, span: 8 }}
                    onChange={(e: any) => search(e.target.value)}
                  />
                </Col>
                {renderFulfillerFilter()}
              </Row>
              {renderSummary()}
              <Table
                total={count}
                pageSize={PAGINATION_PAGE_SIZE}
                onSort={sort}
                onNextPage={nextPage}
                onPreviousPage={previousPage}
                loading={isLoading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={orders}
                rowKey={(rec) => rec.id}
                rowClassName={() => "cursor-pointer"}
                onRow={(record, index) => ({
                  onClick: (e) => onRowClick(record, e),
                })}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </ContentLayout>
  );
};

const adminColumns = [
  {
    title: "Order no.",
    dataIndex: "order_number",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    sorter: true,
    render: (val: string) => moment(val).format("l"),
  },
  {
    title: "Total",
    dataIndex: "total",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (val: OrderStatus) => (
      <Tooltip title={val}>
        <div>
          <OrderStatusBadge status={val} />
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Customer name",
    dataIndex: "customer_name",
    sorter: true,
    render: (val: string) => capitalize(val),
  },
  {
    title: "Fulfiller",
    dataIndex: "fulfillers",
  },
];

const getFulfillerColumns = ({
  onGenLabel,
}: {
  onGenLabel: (row: any, e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => [
  {
    title: "Order no.",
    dataIndex: "id",
  },
  {
    title: "Total",
    dataIndex: "total",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    sorter: true,
    render: (val: string) => moment(val).format("l"),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (val: OrderStatus) => (
      <Tooltip title={val}>
        <div>
          <OrderStatusBadge status={val} />
        </div>
      </Tooltip>
    ),
  },
  {
    title: "Customer name",
    dataIndex: "customer_name",
    sorter: true,
    render: (val: string) => capitalize(val),
  },
  {
    title: "Action",
    render: (_: any, row: any) => (
      <Button
        name="createlabel"
        style={{ fontSize: 12 }}
        shape="round"
        size="small"
        type="primary"
        onClick={(e) => onGenLabel(row, e)}
      >
        Generate label
      </Button>
    ),
  },
];
