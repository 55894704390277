import React, { FC } from "react";
import { ContentLayout } from "../../components/ContentLayout";
import { SearchBar } from "../../components/SearchBar";
import { PrivateRouteProps } from "../../types";
import { Table } from "../../components/Table";
import { useHistory } from "react-router-dom";
import { useProducts } from "../../hooks/useProducts";
import { PAGINATION_PAGE_SIZE } from "../../configs";
import shopItem from "../../assets/shop-item.png";

const columns = [
  {
    render: () =>  <img src={shopItem} alt="shop item" style={{ height: 30 }} />
  },
  {
    title: "SKU",
    dataIndex: "sku",
  },
  {
    title: "Product",
    dataIndex: "product_name",
  },
  {
    title: "Brand",
    dataIndex: "brand",
  },
  {
    title: "Incoming",
    dataIndex: "incoming",
  },
  {
    title: "Available",
    dataIndex: "inventory",
  },
  {
    title: "Fulfillers",
    dataIndex: "fulfillers",
  },
];

export const Inventory: FC<PrivateRouteProps> = ({ isAdmin }) => {
  const {
    products,
    isLoading,
    search,
    count,
    nextPage,
    previousPage,
  } = useProducts(PAGINATION_PAGE_SIZE);
  const history = useHistory();

  const onProductSelect = (
    row: any,
    _e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    console.log("selected product:", row);
    if (isAdmin) {
      history.push(`/dashboard/inventory/details?product_variant_id=${row.id}`);
    }
  };

  return (
    <ContentLayout title="Inventory">
      <div
        style={{ backgroundColor: "white", borderRadius: 16 }}
        className="px-3 py-5"
      >
        <SearchBar
          col={{ xs: 24, md: 12, lg: 8 }}
          placeholder="Search SKU"
          onChange={(e: any) => search(e.target.value)}
        />
        <Table
          total={count}
          pageSize={PAGINATION_PAGE_SIZE}
          loading={isLoading}
          columns={columns}
          dataSource={products}
          onNextPage={nextPage}
          onPreviousPage={previousPage}
          rowClassName={() => (isAdmin ? "cursor-pointer" : "cursor-auto")}
          onRow={(record, rowIndex) => ({
            onClick: (e) => onProductSelect(record, e),
          })}
        />
      </div>
    </ContentLayout>
  );
};
