import React, { FC, useMemo, useState } from "react";
import { InputNumber, Space, Button, SpaceProps, message } from "antd";
import { isNil } from "lodash";
import { useUpdateStock } from "../hooks/useUpdateStock";

interface Props extends SpaceProps {
  quantity: number;
  variantId: number;
  fulfillerId: number;
  location?: string;
  onSuccess?: () => Promise<any>;
}

export const UpdateStockLevel: FC<Props> = ({
  onSuccess = async () => {},
  quantity,
  variantId,
  fulfillerId,
  location,
  ...props
}) => {
  const [value, setValue] = useState<number | null>(null);
  const { mutateAsync, isLoading } = useUpdateStock();

  const isDisabled = useMemo(() => isNil(value), [value])

  const handleChange = (val: number) => {
    setValue(val);
  };

  const handleSave = async () => {
    try {
      if(isNil(value)) {
        message.error("invalid number");
        return;
      }
     const res = await mutateAsync({
      inventory: Number(value),
      product_variant_id: Number(variantId),
      fulfiller_id: Number(fulfillerId),
      location: location,
    })
    await handleSuccess(res)
    } catch(err) {
      handleError(err);
    }
  };

  const handleSuccess = async (data: any) => {
    try {
      console.log("update stock response:", data);
      await onSuccess();
      message.success("Stock successfully updated");
    } catch (err) {
      console.log("inventory refetch error:", err);
      message.error("Inventory refresh error");
    }
  }

  const handleError = async (err: Error) => {
    console.error("update stock error:", err);
    message.error("Stock update failed");
  }

  return (
    <>
      <Space {...props}>
        <InputNumber
          min={0}
          defaultValue={quantity}
          style={{ width: 100 }}
          onChange={handleChange}
        />
        <Button
          type="primary"
          shape="round"
          size="small"
          style={{ fontSize: 12 }}
          onClick={handleSave}
          disabled={isDisabled}
          loading={isLoading}
        >
          Save
        </Button>
      </Space>
    </>
  );
};
