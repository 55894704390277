import React, { createContext, useReducer } from "react";
import { Reducer } from "./reducer";

const authUser = localStorage.getItem("authUser");

const initialState: any = {
  authUser: authUser ? JSON.parse(authUser) : null,
};

const Store: React.FC<React.ReactNode> = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
