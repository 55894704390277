import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Alert, Typography } from "antd";
import { ErrorMessage } from "../components/ErrorMessage";
import { useAuth } from "../hooks/useAuth";
import logo from "../assets/logo.png";
import successImg from "../assets/passwordsent.svg";

export const SendResetPasswordEmail = () => {
  const { sendPasswordResetEmail } = useAuth();
  const [email, setEmail] = useState<string>("");

  const onFinish = (values: any) => {
    console.log("values:", values);
    setEmail(values.email);
    sendPasswordResetEmail.mutate(values.email);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const renderContent = () => {
    if (sendPasswordResetEmail.isSuccess) {
      return <img alt="password sent success" src={successImg} />;
    }
    return (
      <div style={{ backgroundColor: "white", padding: 50, borderRadius: 16 }}>
        <div>
          <img src={logo} alt="logo" />
        </div>
        <Typography.Title level={2} className="my-4">
          Forgot password
        </Typography.Title>
        <Typography>
          Kindly enter your email address and we’ll send you the link to reset
          your password if your email is registered with FBE.
        </Typography>
        <ErrorMessage
          isError={sendPasswordResetEmail.isError}
          message="An error occurred"
          className="mb-5"
        />
        {sendPasswordResetEmail.isSuccess ? (
          <Alert
            showIcon
            type="success"
            message={`A password reset email has been sent to ${email}`}
          />
        ) : null}
        <div className="mt-5">
          <Form
            layout="vertical"
            name="send-reset-password-email"
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email!",
                  type: "email",
                },
              ]}
            >
              <Input
                placeholder="Email"
                size="large"
                style={{ borderRadius: 8 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={sendPasswordResetEmail.isLoading}
                className="mt-2"
                style={{ height: 50, borderRadius: 8, fontWeight: 600 }}
              >
                Send Password Reset Email
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <div className="p-10 background">
      <Row justify="center" align="middle" className="mt-10">
        <Col xs={24} md={12} lg={8}>
          {renderContent()}
        </Col>
      </Row>
    </div>
  );
};
