import { BREAKPOINTS } from "../configs"
import { useBreakPoint } from "./useBreakPoint";

type BreakPointVals = {
  sm?: any; // mobile
  md?: any; // tablet
  lg?: any; // desktop
  base?: any; // default
}
const tranformBreakPointKey = (breakpoint: "mobile" | "tablet" | "desktop") => {
  switch(breakpoint) {
    case "mobile":
      return "sm";
    case "tablet":
      return "md";
    case "desktop":
      return "lg";
    default: 
       return "sm"
  }
}

export const UseBreakPointValue = (values: BreakPointVals, breakpoints = BREAKPOINTS) => {
  const { breakpoint } = useBreakPoint()
  const valueKey = tranformBreakPointKey(breakpoint);
  // todo: letter missing break point values bubble down to the next breakpoint value
  // if base key-value is also missing
  if(values.hasOwnProperty(valueKey)) {
    return values[valueKey];
  } else if(values.base) {
    return values.base;
  }
  return undefined;
}