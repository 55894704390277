import { AxiosResponse } from "axios";
import { DebouncedFunc, throttle } from "lodash";
import { useCallback, useRef, useState } from "react";
import { QueryObserverResult, useQuery } from "react-query";
import { API } from "../api";
import { PAGINATION_PAGE_SIZE, SEARCH_THROTTLE_MILISECONDS } from "../configs";
import { User } from "../types";
import { getQuery } from "../utils";

type FulfillersHook = QueryObserverResult<AxiosResponse<any>, unknown> & {
  items: User[];
  count: number;
  search: DebouncedFunc<(e: any) => Promise<void>>;
  nextPage: () => void;
  previousPage: () => void;
};

export const useFulfillers = (limit = PAGINATION_PAGE_SIZE): FulfillersHook => {
  // query params states
  const [page, setPage] = useState<string>();
  const [q, setQ] = useState<string>();

  const queryKeys = getQuery({ q, page, limit });

  const query = useQuery(["fulfillers", queryKeys], () =>
    API.getFulfillers(queryKeys)
  );

  const data = query.data?.data;

  const nextPage = useCallback(() => {
    const nextPage = data?.next_page;
    if (nextPage) setPage(nextPage);
  }, [data?.next_page]);

  const previousPage = useCallback(() => {
    const prevPage = data?.previous_page;
    if (prevPage) setPage(prevPage);
  }, [data?.previous_page]);

  const { current: search } = useRef(
    throttle(async (keyword: string) => {
      if (keyword) {
        setQ(keyword);
      } else {
        setQ(undefined);
      }
    }, SEARCH_THROTTLE_MILISECONDS)
  );

  return {
    ...query,
    nextPage,
    previousPage,
    search,
    count: query.data?.data.count || 0,
    items: query.data?.data.items || [],
  };
};
