import { useState, useReducer, useCallback } from "react";

type CurrentPageReducerActions =
  | { type: "SET"; page?: number }
  | { type: "NEXT" | "PREV" };

class Pagination {
  static getNextEnabled = (currentPage: number, totalPages: number) => {
    return currentPage < totalPages;
  };
  static getPreviousEnabled = (currentPage: number) => {
    return currentPage > 1;
  };
  static getTotalPages = (totalItems: number, pageSize: number) => {
    return Math.ceil(totalItems / pageSize);
  };

  static getReducer = (page: number, total: number, pageSize: number) => (
    state = page,
    action: CurrentPageReducerActions
  ) => {
    switch (action.type) {
      case "SET":
        return action.page;
      case "NEXT":
        if (
          !Pagination.getNextEnabled(
            state,
            Pagination.getTotalPages(total, pageSize)
          )
        ) {
          return state;
        }
        return state + 1;
      case "PREV":
        if (!Pagination.getPreviousEnabled(state)) {
          return state;
        }
        return state - 1;
      default:
        return state;
    }
  };
}

export const usePagination = ({ total = 0, size = 0, page = 1 }) => {
  const [pageSize, updatePageSize] = useState<number>(size);
  const reducer = Pagination.getReducer(page, total, size);
  const [currentPage = 1, dispatch] = useReducer(reducer, page);

  const setPage = useCallback(
    (page: number) => dispatch({ type: "SET", page }),
    [dispatch]
  );

  const setNextPage = useCallback(() => {
    dispatch({ type: "NEXT" });
  }, [dispatch]);

  const setPreviousPage = useCallback(() => {
    dispatch({ type: "PREV" });
  }, [dispatch]);

  const setPageSize = useCallback(
    (pageSize: number, nextPage = 0) => {
      updatePageSize(pageSize);
      dispatch({ type: "SET", page: nextPage });
    },
    [updatePageSize]
  );

  const totalPages = Pagination.getTotalPages(total, pageSize);

  return {
    total,
    totalPages,
    pageSize,
    currentPage,
    setPage,
    setNextPage,
    setPreviousPage,
    setPageSize,
    nextEnabled: Pagination.getNextEnabled(currentPage, totalPages),
    previousEnabled: Pagination.getPreviousEnabled(currentPage),
  };
};
