import React from "react";
import { Form, Input, Button, Row, Col, Typography } from "antd";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "../components/ErrorMessage";
import { useAuth } from "../hooks/useAuth";
import { CreatePasswordPayload } from "../api";
import logo from "../assets/logo.png";

export const ResetPasswordCallback = () => {
  const { token } = useParams<{ token: string }>();
  const { resetPassword } = useAuth();

  const onFinish = ({ password }: CreatePasswordPayload) => {
    resetPassword.mutate({ token, password });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="p-10 background">
      <Row justify="center" align="middle" className="mt-10">
        <Col xs={24} md={12} lg={8}>
          <div
            style={{ backgroundColor: "white", padding: 50, borderRadius: 16 }}
          >
            <div>
              <img src={logo} alt="logo" />
            </div>
            <Typography.Title level={2} className="mt-4">
              Create new password
            </Typography.Title>
            <div className="mb-10" />
            <ErrorMessage
              isError={resetPassword.isError}
              message="An error occurred"
              className="mb-5"
            />
            <Form
              layout="vertical"
              name="reset-password-callback"
              initialValues={{}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label=""
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  style={{ borderRadius: 8 }}
                />
              </Form.Item>
              <Form.Item
                label=""
                name="confirmpassword"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  size="large"
                  style={{ borderRadius: 8 }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={resetPassword.isLoading}
                  className="mt-2"
                  style={{ height: 50, borderRadius: 8, fontWeight: 600 }}
                >
                  Set New Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
