import { useAuth } from "./useAuth";
import {
  AiOutlineShoppingCart,
  AiOutlineInbox,
  AiOutlineTeam,
} from "react-icons/ai";
import { Home } from "../pages/private/Home";
import { Orders } from "../pages/private/Orders";
import { OrderDetail } from "../pages/private/OrderDetail";
import { Inventory } from "../pages/private/Inventory";
import { Fulfillers } from "../pages/private/Fullfillers";
import { InventoryDetail } from "../pages/private/InventoryDetail";
import { FulfillerDetail } from "../pages/private/FulfillerDetail";
import { InventoryHistory } from "../pages/private/InventoryHistory";

const routes = [
  /**
   * 
  {
    name: "home",
    icon: AiOutlineHome,
    path: undefined,
    getLink: (url: string) => url,
   },
   */
  {
    name: "orders",
    icon: AiOutlineShoppingCart,
    path: "orders",
    getLink: (url: string) => `${url}/orders`,
  },
  {
    name: "inventory",
    icon: AiOutlineInbox,
    path: "inventory",
    getLink: (url: string) => `${url}/inventory`,
  },
  {
    name: "fulfillers",
    icon: AiOutlineTeam,
    path: "fulfillers",
    getLink: (url: string) => `${url}/fulfillers`,
  },
];

export const useDashboardRoutes = () => {
  const { currentUser } = useAuth();
  const isAdmin = !!currentUser?.permissions.includes("admin");

  const routeItems = routes.filter((item) => {
    if (!isAdmin && item.name === "fulfillers") {
      return false;
    }
    return true;
  });

  const getRoutes = (path: string) => [
    { path, component: Home },
    { path: `${path}/orders`, component: Orders },
    { path: `${path}/orders/:orderId`, component: OrderDetail },
    { path: `${path}/inventory`, component: Inventory },
    {
      // must be filtered by sku or product_variant_id for now
      path: `${path}/inventory/details`,
      component: InventoryDetail,
    },
    {
      path: `${path}/inventory/history/:variantId`,
      component: InventoryHistory,
    },
    { path: `${path}/fulfillers`, component: Fulfillers },
    { path: `${path}/fulfillers/:id`, component: FulfillerDetail },
  ];

  return {
    getRoutes,
    routeItems,
  };
};
