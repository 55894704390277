import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { API } from "../api";
import { PAGINATION_PAGE_SIZE } from "../configs";

export const useInventoryHistory = (
  variantId: number,
  limit = PAGINATION_PAGE_SIZE
) => {
  const client = useQueryClient();
  const [page, setPage] = useState<string>();

  const query = useQuery(
    ["inventory-history", { page, variantId }],
    () =>
      API.getInventoryHistory({ limit, page, product_variant_id: variantId }),
    { keepPreviousData: true }
  );

  const prefetch = useCallback(
    async (page: string) => {
      await client.prefetchQuery(["fulfiller", { page }], () =>
        API.getInventoryHistory({ limit, page })
      );
    },
    [client, limit]
  );

  const data = query.data?.data;

  useEffect(() => {
    const nextPage = data?.next_page;
    if (nextPage) prefetch(nextPage);
  }, [data?.next_page, prefetch]);

  const nextPage = useCallback(() => {
    const nextPage = data?.next_page;
    if (nextPage) setPage(nextPage);
  }, [data?.next_page]);

  const previousPage = useCallback(() => {
    const prevPage = data?.previous_page;
    if (prevPage) setPage(prevPage);
  }, [data?.previous_page]);

  return {
    isLoading: query.isLoading,
    count: data?.count,
    items: data?.items,
    nextPage,
    previousPage,
  };
};
