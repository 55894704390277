import {
  Divider,
  Drawer,
  Row,
  Col,
  Select,
  Typography,
  Input,
  Space,
  Button,
  message,
} from "antd";
import { omit } from "lodash";
import React, { FC, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useQueryClient } from "react-query";
import { UseBreakPointValue } from "../hooks/useBreakPointValue";
import { useLabel } from "../hooks/useLabel";
import { useUpdateOrderStatus } from "../hooks/useUpdateOrderStatus";

interface Props {
  orderId: number;
  visible: boolean;
  toggle: () => void;
}

const packages = [
  {
    name: "Medium Standard Box",
    width: 20,
    length: 30,
    height: 10,
    weight: 50.2,
  },
];

export const GenerateLabelDrawer: FC<Props> = ({
  visible,
  toggle,
  orderId,
}) => {
  const defaultPackage = packages[0];
  const drawerWidth = UseBreakPointValue({ base: "100%", md: 500, lg: 600 })

  const [page, setPage] = useState<number>(1);
  const [rates, setRates] = useState<any[]>([]);
  const [rate, setRate] = useState<any>(null);
  const [dimensions, setDimensions] = useState<any>(
    omit(defaultPackage, ["name"])
  );

  const { createPackage, purchaseLabel } = useLabel();
  const updateStatus = useUpdateOrderStatus();
  const client = useQueryClient();

  const nextPage = async () => {
    try {
      const { data } = await createPackage.mutateAsync({
        orderId,
        payload: dimensions,
      });
      console.log("rates:", data);
      if (data) {
        setRates(data);
        setRate(data[0]);
      }
      setPage(2);
    } catch (err) {
      message.error("Failed to create label");
    }
  };

  const previousPage = () => {
    setPage(1);
  };

  const onPackageSelect = (val: string) => {
    console.log("selection:", val);
    const pack = packages.find((p) => p.name === val);
    if (pack) {
      setDimensions(omit(pack, ["name"]));
    }
  };

  const onDimensionsChange = (field: string) => {
    return (e: any) => {
      setDimensions({ ...dimensions, [field]: e.target.value });
    };
  };

  const onSelectRate = (rate: any) => {
    setRate(rate);
  };

  const printLabel = async () => {
    try {
      const { data } = await purchaseLabel.mutateAsync({
        orderId,
        rateId: rate?.id,
      });
      if (data) {
        window.open(data.shipping_label, "_blank");
      }
    } catch (err) {
      console.error("print label err:", err);
      message.error("Failed to print label");
    }
  };

  const markAsShipped = async () => {
    try {
      await updateStatus.mutateAsync({ orderId, status: "shipped" });
      await client.invalidateQueries("orderDetail")
      toggle();
      message.success("Successfully marked order as shipped");
    } catch (err) {
      console.error("update order status error:", err);
      message.error("Failed to mark order as shipped");
    }
  };

  const renderFirstPage = () => (
    <>
      <Typography style={{ fontSize: 16 }}>Step 1 of 2</Typography>
      <Typography.Title style={{ marginTop: 18 }} level={2}>
        Select package
      </Typography.Title>
      <Typography
        className="mb-1 mt-6"
        style={{ color: "#6B6782", fontSize: 16 }}
      >
        Packages
      </Typography>
      <Select
        size="large"
        defaultValue={defaultPackage.name}
        style={{ width: 300, borderRadius: 4 }}
        onSelect={onPackageSelect}
      >
        {packages.map((p) => (
          <Select.Option key={p.name} value={p.name}>
            {p.name}
          </Select.Option>
        ))}
      </Select>

      <Space className="mt-5">
        <div>
          <Typography className="mb-1" style={{ color: "#6B6782" }}>
            Dimension
          </Typography>
          <Space>
            <Input
              value={dimensions.width}
              onChange={onDimensionsChange("width")}
              size="large"
              style={{ width: 90 }}
              bordered
              addonAfter="w"
            />
            <Input
              value={dimensions.length}
              onChange={onDimensionsChange("length")}
              size="large"
              style={{ width: 90 }}
              addonAfter="l"
            />
            <Input
              value={dimensions.height}
              onChange={onDimensionsChange("height")}
              size="large"
              style={{ width: 90 }}
              addonAfter="h"
            />
          </Space>
        </div>
        <div className="ml-3">
          <Typography className="mb-1" style={{ color: "#6B6782" }}>
            Weight
          </Typography>
          <Space>
            <Input
              bordered
              size="large"
              addonAfter="Ib"
              style={{ width: 120 }}
              value={dimensions.weight}
              onChange={onDimensionsChange("weight")}
            />
          </Space>
        </div>
      </Space>
    </>
  );

  const renderSecondPage = () => (
    <>
      <Space>
        <BiArrowBack size={20} onClick={previousPage} />
        <Typography style={{ fontSize: 16 }}>Step 2 of 2</Typography>
      </Space>

      <Typography.Title style={{ marginTop: 18 }} level={2}>
        Select Courier
      </Typography.Title>

      <Typography style={{ fontSize: 16 }}>
        Customer delivery option:
      </Typography>
      <Typography style={{ fontWeight: 600, fontSize: 16 }}>
        Customer chose a standard delivery
      </Typography>

      <div className="mt-8">
        <Typography className="mb-1" style={{ color: "#6B6782", fontSize: 16 }}>
          Courier options
        </Typography>
        <Select defaultValue={rate?.id} style={{ width: "100%" }} size="large">
          {rates.map((rate) => (
            <Select.Option key={rate.id} value={rate.id}>
              <Row
                onClick={() => onSelectRate(rate)}
                justify="space-between"
              >
                <span>{`${rate.carrier} $${rate.rate}`}</span>
                <span className="pr-4">{rate.service}({rate.delivery_days} days)</span>
              </Row>
            </Select.Option>
          ))}
        </Select>
      </div>

      <div
        style={{ backgroundColor: "#F8F9FB", borderRadius: 8 }}
        className="p-5 mt-10"
      >
        <Typography className="mb-5" style={{ fontWeight: 600, fontSize: 16 }}>
          Package detail
        </Typography>
        <Row gutter={40}>
          <Col>
            <Typography>Package:</Typography>
            <Typography style={{ fontSize: 16 }}>
              Medium standard box
            </Typography>
          </Col>
          <Col>
            <Typography>Dimensions:</Typography>
            <Typography style={{ fontSize: 16 }}>20x20x20</Typography>
          </Col>
          <Col>
            <Typography>Weight:</Typography>
            <Typography style={{ fontSize: 16 }}>3lbs, 8oz</Typography>
          </Col>
        </Row>
      </div>
    </>
  );

  const renderFooter = () => {
    if (page === 2) {
      return (
        <Space>
          <Button
            onClick={markAsShipped}
            size="large"
            shape="round"
            style={{ fontWeight: 600 }}
          >
            Mark as shipped
          </Button>
          <Button
            onClick={printLabel}
            type="primary"
            size="large"
            shape="round"
            style={{ fontWeight: 600 }}
          >
            Print Label
          </Button>
        </Space>
      );
    }
    return (
      <Button
        onClick={nextPage}
        style={{ fontWeight: 600 }}
        shape="round"
        size="large"
        type="primary"
      >
        Next
      </Button>
    );
  };

  return (
    <Drawer
      visible={visible}
      width={drawerWidth}
      bodyStyle={{ backgroundColor: "#E6EEF0", padding: 0 }}
      maskClosable={false}
      onClose={toggle}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <Typography.Title level={3} className="p-5">
            Create a label
          </Typography.Title>
          <Split />
          <div className="p-5">
            {page === 2 ? renderSecondPage() : renderFirstPage()}
          </div>
        </div>
        <div>
          <Split />
          <Row justify="end" className="p-5">
            {renderFooter()}
          </Row>
        </div>
      </div>
    </Drawer>
  );
};

const Split = () => (
  <Divider
    type="horizontal"
    className="bg-white h-1"
    style={{ height: 1.6, marginTop: -10 }}
  />
);
